import './assets/css/bootstrap.min.css';
import './assets/css/bootstrap-reboot.min.css';
import './assets/vendors/bootstrap-selector/css/bootstrap-select.min.css';
import './assets/vendors/themify-icon/themify-icons.css';
import './assets/vendors/flaticon/flaticon.css';
import './assets/vendors/animation/animate.css';
import './assets/vendors/owl-carousel/assets/owl.carousel.min.css';
import './assets/vendors/magnify-pop/magnific-popup.css';
import './assets/vendors/nice-select/nice-select.css';
import './assets/css/style.css';
import './assets/css/responsive.css';
import './assets/css/rtl.css';
import './assets/css/custom.css?v=2.0';

const onRouteUpdate = ({location, prevLocation}) => {
    setTimeout(function () {
        window.initAll();
        // window.$crisp && window.$crisp.push(["do", "message:thread:start", ["Ticket 11111"]]);
        // window.$crisp && window.$crisp.push(["do", "session:reset"]);
    }, 200);
}
const shouldUpdateScroll = ({
                                routerProps: {location},
                                getSavedScrollPosition
                            }) => {


    if (location && location.hash) {
        setTimeout(function () {
            const anchor = document.querySelector(location.hash)
            const offset = anchor.getBoundingClientRect().top + window.scrollY
            window.scrollTo({top: offset, left: 0});
        }, 200);

        return false;
    } else {
        window.scrollTo({top: 0, left: 0})
        return false
    }
}

export {
    onRouteUpdate,
    shouldUpdateScroll,
};
